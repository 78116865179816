import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Launch } from '@mui/icons-material';
import * as c from '../../../utils/constants/constants';
import { GetCurrentDateAndTime, FormatDate } from "commonFunctions/commonFunctions";


const ExportUser = (props) => {

    const [dummy_headers, updateDummyHeaders] = useState([]);
    const [combined_json, updateCombinedJson] = useState([]);

    useEffect(() => {

        let tempDate = ` for between ${FormatDate({ date: props.filter.from_date, format: "MM/DD/YYYY" })} and ${FormatDate({ date: props.filter.to_date, format: "MM/DD/YYYY" })}`;
        let d_headers = [
            { label: "Document: " + c.PRODUCT_NAME + " user Performance report " + tempDate + ".", key: "column_0" },
            { label: '', key: "column_1" },
            { label: '', key: "column_2" },
        ]

        let length = props.actionColumn.filter(obj => obj.enabled === true).map(obj => obj.label).length

        for (let i = 0; i < length; i++) {
            d_headers.push({
                label: "",
                key: "column_" + (i + 3)
            })
        }
        updateDummyHeaders(d_headers);

        const export_info = [
            { column_0: "Export By:", column_1: localStorage.getItem(c.USER_NAME), column_2: "" },
            { column_0: "Export Date/Time:", column_1: GetCurrentDateAndTime("MM/DD/YYYY, HH:mm:ss"), column_2: "" },
        ];

        const empty_row = [
            { column_0: "" }
        ];

        const report_end = [
            { column_0: "<End of Report>", column_1: "", column_2: "" }
        ];


        const table_head = getTableHeader(props.actionColumn);
        const table_total_head = getTableTotalHeader(props.actionColumn, props.userData,props.search)
        const table_data = getTableData(props.actionColumn, props.userData, props.search)

        let combined_json = [];
        combined_json = export_info
            .concat(empty_row)
            .concat([{ column_0: "User Performance Report" }])
            .concat(empty_row)
            .concat(table_head.headers)
            .concat(table_total_head.headers)
            .concat(table_data.data[0])
            .concat(empty_row)
            .concat(report_end);
        updateCombinedJson(combined_json);
    }, [props.actionColumn, props.userData, props.search]);

    const getTableHeader = (rawData) => {

        let enabledAction = rawData.filter(obj => obj.enabled === true).map(obj => obj.label)
        let headers = { column_0: "User ID",column_1 : "User Name" };
        enabledAction.forEach((m, i) => {
            headers["column_" + (i + 2)] = m;
        });
        const keys = Object.keys(headers);
        const lastKey = keys[keys.length - 1];
        const lastNumber = parseInt(lastKey.split('_')[1]);
        const newKey = `column_${lastNumber + 1}`;
        headers[newKey] = "Total count";

        return {
            headers: [(headers || {})],
        }
    }

    const getTableTotalHeader = (rawData, userData,search) => {
        let result = {}
        let enabledAction = rawData.filter(obj => obj.enabled === true).map(obj => obj.key)

        const filteredData = userData.filter((item) => {
            // return ((item.user_id.includes(props.search) || props.search === '') || item?.user_name.toLowerCase().includes(props.search.toLowerCase()));
            return (item?.user_name.toLowerCase().includes(search.toLowerCase()));
          });

        enabledAction.forEach((action) => {
            result[action] = filteredData.reduce((acc, obj) => acc + (obj[action] || 0), 0);
        });

        const updatedList = rawData.map(item => {
            if (result.hasOwnProperty(item.key)) {
                return {
                    ...item,
                    [item.key]: result[item.key]
                };
            }
            return item;
        });


        let headers = { column_1: "Total" };
        updatedList && updatedList.filter(res => enabledAction.includes(res.key)).forEach((m, i) => {
            headers["column_" + (i + 2)] = m[m.key];
        });
        const keys = Object.keys(headers);
        const lastKey = keys[keys.length - 1];
        const lastNumber = parseInt(lastKey.split('_')[1]);
        const newKey = `column_${lastNumber + 1}`;
        headers[newKey] = getTotalCount(updatedList && updatedList.filter(obj => obj.enabled === true), enabledAction);

        return {
            headers: [(headers || {})],
        }
    }


    const getTableData = (enabledAction, data, sortName) => {
        let enabled = enabledAction.filter(obj => obj.enabled === true).map(obj => obj.key)
        const filteredData = data.filter((item) => {
            return ((item.user_id.includes(sortName) || sortName === '') || item?.user_name.toLowerCase().includes(sortName.toLowerCase()));
        });

        const tempList = [];
        for (let i = 0; i < filteredData.length; i++) {
            const res = filteredData[i];
            const mappedData = {};
            let t = 0
            for (let j = 0; j <= enabled.length + 1; j++) {

                if (j !== 0 && j !==1) {
                    mappedData[`column_${j}`] = res[enabled[t]] ? res[enabled[t]] : 0;
                    t = t + 1
                } 
                else if(j == 0){
                    mappedData[`column_${j}`] = res.user_id;
                }
                else {
                    mappedData[`column_${j}`] = res.user_name;
                }
            }

            const newKey = `column_${enabled.length + 2}`;
            mappedData[newKey] = totalActionCount(res, enabledAction)

            tempList.push(mappedData);
        }

        return {
            data: [(tempList || {})]
        }

    }

    const getTotalCount = (data1, data2) => {
        let totalCount = 0;
        data1.forEach(item => {
            if (data2.includes(item.key)) {
                totalCount += item[item.key];
            }
        });
        return totalCount;
    };

    const totalActionCount = (data, enabledObject) => {

        let temp = enabledObject.filter(obj => obj.enabled === true).map(obj => obj.key)

        return temp.reduce((total, action) => {
            return total + (data[action] || 0);
        }, 0);
    };

    return (
        <div className="perform-export-link">
            <Launch />
            <input type="button" className="button-as-link" value="Export" onClick={props.getUserData} />
            <CSVLink
                headers={dummy_headers}
                filename="readabl-user-performance-report.csv"
                data={combined_json}
                ref={props.csvLinkEl}
            />
        </div>
    )

}
export default ExportUser;