import { MENU_OPTIONS } from "utils/constants/NavigationOptions";
import * as c from "utils/constants/constants";

export const GetFilteredMenuData = (menuData, role, isRouting) => {
    let finalData = [];
    finalData = (function recursiveFunction(data, role, isRoute){
        return data.filter(m => {
        if(m.children?.length) {
            let child = recursiveFunction(m.children, role, isRoute);
            if(child?.length) {
                m.children = child;
                return true;
            } else {
                !isRouting && delete m.children;
                return m.showMenu || false;
            };
        } else if(m.role?.includes(role) || m.role?.length == 0 || !m.role) {
            if(isRouting && m.path) {
                return true
            } else if (m.showMenu) return true;
            return false;
        } else return false;
    });
    })(menuData, role, isRouting);
    return finalData;
  }
  
  export const ModifyMenuData = (objArray, currentLevel = 0, prevPath = "") => {
    objArray.forEach(node => {
        node.level = currentLevel;
        node.path = prevPath + "/" + node.value;
        if (node.children && node.children.length > 0) {
            ModifyMenuData(node.children, currentLevel + 1, node.path);
        }
    });
    return objArray;
  }

export const GetSelectedMenus = (searchValue) => {
    let menuOptions = [...MENU_OPTIONS]
    let result = '';
    function search(obj, path) {
        if (obj.value === searchValue) {
            result = path + '/' + obj.value;
            return true;
        }
        if (obj.children) {
            for (let i = 0; i < obj.children.length; i++) {
                if (search(obj.children[i], path + '/' + obj.value)) {
                    return true;
                }
            }
        }
        return false;
    }
    for (let i = 0; i < menuOptions.length; i++) {
        if (search(menuOptions[i], '')) {
            break;
        }
    }
    return result;
};

export const GetRoutingData = (menuOptions) => {
    let menuData = [], routes = []
    if(localStorage.getItem(c.USER_ROLE)) {
        menuData = GetFilteredMenuData(ModifyMenuData(menuOptions), localStorage.getItem(c.USER_ROLE), true)
    }
    menuData.length && (function recursiveFunction(menus, routes) {
        (menus || []).forEach(m => {
            if(m.children?.length) {
                if(m.hasOwnPath) {
                    routes.push({
                        path: m.path,
                        component: m.component,
                        hideNavigationMenu: Boolean(m.hideNavigationMenu),
                    });
                }
                recursiveFunction(m.children, routes);
            } else if (m.path) {
                routes.push({
                    path: m.path,
                    component: m.component,
                    hideNavigationMenu: Boolean(m.hideNavigationMenu),
                })
            }
        });
    })(menuData, routes);
    
    return routes;
}