import React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import {FormControl,TextField,InputAdornment, Select} from'@mui/material';
import {Search} from "@mui/icons-material";
import * as c from "../../utils/constants/constants";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: ''
    }
  }

  handleOptionChange(event) {
    this.setState({
      selectedOption: event.target.value
    }, () => {
      this.props.handleAdornmentChange(event, event.target.value)
    })
  }

  findKey = (e) => {
      if(e.key === 'Enter'){
        this.props.findData('results',this.props.search);
      }else
        return;
  }

    render(){
        return(
            <>
              <div>
                <div>
                  <FormControl>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      id="input-with-icon-textfield"
                      placeholder="Search"
                      name="search"
                      autoComplete="off"
                      disabled={this.props.adornmentOptions?.length && !Boolean(this.state.selectedOption)}
                      onChange={(e) => {
                        this.props.handleChange(e, e.target.value);
                       // this.props.findData("suggest", this.props.search);
                      }}
                      onKeyPress = {(e) => this.findKey(e)}
                      value={this.props.search}
                      sx={{
                        "> div": {
                          paddingLeft: "unset !important"
                        },
                        "input": {
                          width: "260px"
                        }
                        
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {this.props.adornmentOptions?.length ? <Select
                              native
                              value={this.state.selectedOption || ""}
                              variant="standard"
                              onChange={(event) => this.handleOptionChange(event)}
                              inputProps={{
                                name: this.props.adornmentColumn || "selectedOption",
                                id: "outlined-age-native-simple",
                              }}
                              sx={{
                                padding: "0px 0px 0px 10px",
                                height: "40px", 
                                width: "max-content",
                                background: "#e4e4e4",
                                ":after": {
                                  border: "unset !important"
                                },
                                ":before": {
                                  border: "unset !important"
                                },
                                "select:focus": {
                                  background: "unset"
                                }

                              }}
                            >
                              <option value="" disabled>Select search field</option>
                              {(this.props.adornmentOptions || []).map((action) => (
                                <option value={action.key}>
                                  {action.label}
                                </option>
                              ))}
                            </Select> : <Search sx={{
                              padding: "0px 0px 0px 10px",
                            }} />}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {this.props.search.length > 0 && this.props.is_search ? (
                              <CloseIcon
                                className="icon-action"
                                onClick={() => this.props.cancelFilter("search")}
                              />
                            ) : ''}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </div>
                {this.props.showSuggestion && this.props.suggest_box.length > 0 && this.props.is_search !== true && (
                  <div className="suggest-box">
                    {this.props.suggest_box &&
                      this.props.suggest_box.map((data, i) => (
                        <p key = {i}
                          className="suggest-result"
                          onClick={() => this.props.findData("results", data)}
                         
                        >
                          {data}
                        </p>
                      ))}
                  </div>
                )}
              </div>
                </>
        );
    }
}

export default SearchBar;