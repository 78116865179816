import {Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
      backgroundColor: '#f1e8f0',
      color: '#333333',
      boxShadow: theme.shadows[2],
      fontSize: 13,
}));