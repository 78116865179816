import React, { useState } from "react";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import { Grid, Checkbox, Select, FormControl, InputLabel, Input, MenuItem, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import './Filter.css';


const Filter = (props) => {

  const [error, setError] = useState(false)

  const handleChange = (event) => {
    let tempArr = event.target.value
    const updatedList = props.actionColumn.map(item => {
      if (tempArr.includes(item.key)) {
        return { ...item, enabled: !item.enabled };
      }
      return item;
    });
    props.setActionColumn(updatedList)
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return <>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Grid style={{ paddingRight: "20px" }}>
        <FormControl sx={{ m: 1, width: 250 , margin: "8px 2px"}} >
          <InputLabel sx={{marginTop:'8px', transform: "translate(0px, -10px) scale(0.75) !important"}} >Action list</InputLabel>
          <Select
            name="actionName"
            multiple
            value={props.actionColumn.filter(item => item.enabled).map(item => item.label)}
            onChange={e => { handleChange(e) }}
            // input={<Input  label="Action list" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            variant="standard"
          >
            {props.actionColumn.map((name) => (
              <MenuItem
                key={name}
                value={name.key}
                sx={{
                  display: "flex !important"
                }}
              >
                <Checkbox checked={name.enabled === true} />
                <ListItemText primary={name.label} />
              </MenuItem>

            ))} </Select>
        </FormControl>
      </Grid>
      <Grid style={{ paddingRight: "20px" }}>
        <CustomDatePicker
          maxDate={dayjs().format("MM/DD/YYYY")}
          onChange={val => {
            props.handleChange('from_date', val)
          }}
          onError={error => {
            if (error) setError(true);
            else setError(false);
          }}
          value={props?.filter.from_date}
          label="From"
          format={'MM/DD/YYYY'}
        />
      </Grid>
      <Grid style={{ paddingRight: "20px" }}>
        <CustomDatePicker
          minDate={dayjs(props.filter.from_date).format("MM/DD/YYYY")}
          maxDate={dayjs().format("MM/DD/YYYY")}
          onChange={val => {
            props.handleChange('to_date', val);
          }}
          onError={error => {
            if (error) setError(true);
            else setError(false);
          }}
          value={props.filter.to_date}
          label="To"
          format={'MM/DD/YYYY'}
        />
      </Grid>

      <div className="buttons-filter">
        <button
          className="apply-button"
          type="submit"
          style={{ cursor: error ? 'not-allowed' : 'pointer' }}
          onClick={() => !(error) && props.handleSummit()}
        >
          <p className="apply-button-text">Apply</p>
        </button>
      </div>
    </div>
  </>

}
export default Filter;