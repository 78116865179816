import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const defaultValues = {
  format: 'MM/DD/YYYY',
  minDate: '01/01/2020',
};

const errrorMessage = {
  minDate: 'Check allowed dates',
  maxDate: 'Check allowed dates',
  invalidDate: 'Valid dates only accepted',
  disableFuture: 'Future dates are not allowed',
};

const CustomDatePicker = (props) => {
  const [error, updateError] = useState(null);
  const [selectedDate, updateSelectedDate] = useState(null);

  useEffect(() => {
    if (props.value) updateSelectedDate(props.value);
    else updateSelectedDate(null);
  }, [props]);

  useEffect(() => {
    if(Boolean(props.minDate) && Boolean(props.value) && dayjs(props.minDate).isAfter(dayjs(props.value))) {
      updateError("minDate");
      typeof props.onError == 'function' && props.onError("minDate");
    }
  }, [props.value]);
  
  const handleDateChange = (date, error) => {
    // if (error.validationError == null) {
      let formattedDate = dayjs(date).format(
        props.format || defaultValues.format
      );
      updateError(null);
      updateSelectedDate(formattedDate);
      typeof props.onChange == 'function' && props.onChange(formattedDate, date);
    // }
  };
  const handleError = (error) => {
    updateError(error);
    typeof props.onError == 'function' && props.onError(error);
  };

  return (
    <div className="x-date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format={props.format || defaultValues.format}
          label={props.label}
          variant="standard"
          value={selectedDate ? dayjs(selectedDate) : null}
          onChange={handleDateChange}
          onError={handleError}
          disableFuture={Object.hasOwn(props, "disableFuture") ? props.disableFuture : true}
          showDaysOutsideCurrentMonth={true}
          disabled={props.disabled}
          {...(props.hasOwnProperty("minDate") ?  {minDate: dayjs(props.minDate).endOf('day') } : {})}
          {...(props.hasOwnProperty("maxDate") ?  {maxDate: dayjs(props.maxDate).endOf('day') } : {})}
          {...(props.hasOwnProperty("views") && props.hasOwnProperty("openTo") ? {views: props.views, openTo: props.openTo} : {views: ['year', 'month', 'day'], openTo: 'day'})}
          slotProps={{
            textField: {
              helperText: errrorMessage[error],
              variant: 'standard',
            },
          }}
          sx={{
            width: '120px',
            '& input': { fontSize: '1rem' },
            '& svg': { fontSize: '1rem' },
            '& button': { padding: '5px 5px 5px 5px', margin: 'unset' },
            '& > div': { display: 'flex', justifyContent: 'space-between' },
            '& p': { width: 'max-content', position: 'absolute', top: '42px'},
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDatePicker;
