import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { CustomTooltip } from 'components/CustomTooltip/CustomTooltip';
import { ConvertUtcToLocal } from '../../../../commonFunctions/commonFunctions';
import DialogModal from 'components/DialogModel/DialogModel';
import TablePage from 'components/TablePage/TablePage';
import './DocumentLogTable.css';

const DocumentLogTable = (props) => {

    const originalData = useMemo(() => {
        let temp = [];
        (props?.child_audit_log || []).forEach((val) => {
            if (val?.modified_entity?.length == 0) temp.push(val)
            else {
                (val?.modified_entity || []).forEach((res) => {
                    temp.push({ ...val, ...res })
                })
            }
        });
        return temp;
    }, [props?.child_audit_log]);

    const [finalArray, setFinalArray] = useState([]);

    useEffect(() => {
        if(originalData?.length) {
            let tempData = filterData(originalData, props?.childPageNumber, props?.recordsPerPage);
            setFinalArray(tempData);
        } else setFinalArray([]);

    }, [originalData, props?.childPageNumber]);

    const filterData = (data = [], pageNumber, recordsPerPage) => {
        let start = pageNumber * recordsPerPage;
        let end = start + recordsPerPage
        return data.slice(start, end);
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className="thBorder">
                        <TableRow>
                            <TableCell className="tableHeadStyle"></TableCell>
                            {props.documentTableHeadData.map((data, i) => (
                                <TableCell key={i}
                                    className="tableHeadStyle"
                                    align="left"
                                    onClick={() => props.sort(data.name, 'document')}
                                >
                                    <div className="sortSection">
                                        <Typography className="textTheme">{data.name}</Typography>
                                        <div className={data.name === props.audit_filter.sort_key ? "sort-icons" : "sort-icons-hide"}
                                        >
                                            {data.name === props.audit_filter.sort_key && props.audit_filter.sort_order === true ? (
                                                <ArrowUpward fontSize="small" style={{ color: "grey" }} />
                                            ) : data.name === props.audit_filter.sort_key && props.audit_filter.sort_order === false ?
                                                <ArrowDownward style={{ color: "grey" }} fontSize="small" /> : null }
                                        </div>
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.audit_data && props.audit_data.map((data, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell className="tableCellStyle" >
                                        <div>
                                            {props.childTableOpened == data.doc_id ?
                                                <KeyboardArrowUp fontSize="medium"
                                                    style={{ color: "grey", marginBottom: '-6px', cursor: 'pointer' }} onClick={() => props.toggleSubTable(data.doc_id)} /> :
                                                <KeyboardArrowDown fontSize="medium"
                                                    style={{ color: "grey", marginBottom: '-6px', cursor: 'pointer' }} onClick={() => props.toggleSubTable(data.doc_id, true)} />}
                                            {data.documentName}</div>
                                    </TableCell>
                                    <TableCell className="tableCellStyle" align="left">
                                        {data.document_name.length > 40 ? (
                                            <CustomTooltip title={data.document_name} placement="top-start">
                                                <div style={{ cursor: 'pointer' }}>
                                                    <p className="doc-name-focus" onClick={() => props.goToIntervention(data.doc_id)}>
                                                        {data.document_name.length > 40
                                                            ? data.document_name.substr(0, 25) +
                                                            "..." +
                                                            data.document_name.substr(
                                                                data.document_name.length - 10,
                                                                data.document_name.length
                                                            )
                                                            : data.document_name}
                                                    </p>
                                                    <p style={{ margin: '0px', fontSize: '0.75em', color: '#a2a0a0' }}>{data.doc_id}</p>
                                                </div>
                                            </CustomTooltip>
                                        ) : (
                                            <div style={{ cursor: 'pointer' }}>
                                                <p onClick={() => props.goToIntervention(data.doc_id)} className="doc-name-focus">{data.document_name}</p>
                                                <p style={{ margin: '0px', fontSize: '0.75em', color: '#a2a0a0' }}>{data.doc_id}</p>
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell className="tableCellStyle" align="left">
                                        {data.original_file_name.length > 40 ? (
                                            <CustomTooltip title={data.original_file_name} placement="top-start">
                                                <div>
                                                    <p className="doc-name-blur" >
                                                        {data.original_file_name.length > 40
                                                            ? data.original_file_name.substr(0, 25) +
                                                            "..." +
                                                            data.original_file_name.substr(
                                                                data.original_file_name.length - 10,
                                                                data.original_file_name.length
                                                            )
                                                            : data.original_file_name}
                                                    </p>
                                                </div>
                                            </CustomTooltip>
                                        ) : (
                                            <div>
                                                <p className="doc-name-blur">{data.original_file_name}</p>
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell className="tableCellStyle" ><div>{data.location}</div></TableCell>
                                    <TableCell className="tableCellStyle" ><div>{data.Sub_Category}</div></TableCell>
                                    <TableCell className="tableCellStyle" ><div>{ConvertUtcToLocal(data.document_received_date + " " + data.document_received_time + " UTC", "MM/DD/YYYY, hh:mm A")} </div></TableCell>
                                    <TableCell className="tableCellStyle" ><div>{ConvertUtcToLocal(data.last_updated_date + " " + data.last_updated_time + " UTC", "MM/DD/YYYY, hh:mm A")} </div></TableCell>
                                </TableRow>

                                {props.childTableOpened == data.doc_id ? (

                                    <TableRow>
                                        <TableCell colSpan={7} >
                                            <Box>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className="ChildHeadStyle"><div>User Name</div></TableCell>
                                                                <TableCell className="ChildHeadStyle"><div>Action</div></TableCell>
                                                                <TableCell className="ChildHeadStyle"><div>Field</div></TableCell>
                                                                <TableCell className="ChildHeadStyle"><div>Previous Value</div></TableCell>
                                                                <TableCell className="ChildHeadStyle"><div>Current Value</div></TableCell>
                                                                <TableCell className="ChildHeadStyle"><div>Updated On</div></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        {finalArray.length > 0 ? (finalArray || []).map((res) =>
                                                            <TableBody>
                                                                <TableRow >
                                                                    <TableCell className="tableCellStyle" ><div>{res.name}</div></TableCell>
                                                                    <TableCell className="tableCellStyle" ><div>{res.action}</div></TableCell>
                                                                    <TableCell className="tableCellStyle" ><div>{res.field ? res.field : '-'}</div></TableCell>
                                                                    <TableCell className="tableCellStyle" ><div>{Array.isArray(res.prevValue) ? res.prevValue.join(', ') : res.prevValue ? Array.isArray(res.prevValue) ? res.prevValue.join(', ') : res.prevValue : '-'}</div></TableCell>
                                                                    <TableCell className="tableCellStyle" ><div>{Array.isArray(res.currentValue) ? res.currentValue.join(', ') : res.currentValue ? Array.isArray(res.currentValue) ? res.currentValue.join(', ') : res.currentValue : '-'}</div></TableCell>
                                                                    <TableCell className="tableCellStyle" ><div>{ConvertUtcToLocal(res.last_updated_date + " " + res.last_updated_time + " UTC", "MM/DD/YYYY, hh:mm A")} </div></TableCell>
                                                                </TableRow>
                                                            </TableBody>) : ""}

                                                        {finalArray?.length == 0 && <TableCell colSpan={6} style={{ textAlign: 'center' }}><DialogModal /></TableCell>}
                                                    </Table>
                                                </TableContainer>
                                                <TablePage
                                                    count={originalData?.length || 0}
                                                    recordsPerPage={props?.recordsPerPage}
                                                    pageNum={props?.childPageNumber}
                                                    handleChangePage={props.handleChildPageChange}
                                                    refresh_list={props?.refresh_list}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <TablePage
                    count={props?.audit_log_length}
                    recordsPerPage={props?.recordsPerPage}
                    pageNum={props?.pageNum}
                    handleChangePage={props.handleChangePage}
                    refresh_list={props?.refresh_list}
                />
            </div>
        </>
    );
};

export default DocumentLogTable;