import React from 'react';
import {TablePagination} from "@mui/material";
import './TablePage.css';


const tablePage = (props) => {
  const disableClick = (e) => {
    if(props.refresh_list && e.key === "Enter"){
      e.preventDefault();
    }
  }
    return <>
    <div className = 'page-num-align' onKeyPress = {(e) => disableClick(e)}>
      <TablePagination
            component="div"
            count= {props.count}
            rowsPerPageOptions={[5]}
            rowsPerPage={props.recordsPerPage}
            page={props.pageNum}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={props.handleChangePage}
          />
          <div className = 'page-box'>
            <p> {props.pageNum+1}</p>  
          </div>
          </div>
          </>;
            
}

export default tablePage;