import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { ConvertUtcToLocal } from '../../../../commonFunctions/commonFunctions';
import DialogModal from 'components/DialogModel/DialogModel';
import TablePage from 'components/TablePage/TablePage';
import './UserLogTable.css';

const UserLogTable = (props) => {

  const [userData, setUserData] = useState(props.audit_data);
  useEffect(() => {
    let temp = [];
    if (props.audit_data.length == 0) {
      setUserData([])
    }
    props.audit_data && props.audit_data.map((res) => {
      if (res?.modified_entity?.length == 0) {
        temp.push(res)
      } else {
        (res?.modified_entity || []).map((value) => {
          temp.push({ ...res, ...value })
        })
      }
      setUserData(temp)
    })
  }, [props.audit_data])

  return <>
    <div className='userLogTable'>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className="thBorder">
            <TableRow>
              {props.userTableHeadData && props.userTableHeadData.map((data, i) => (
                <TableCell key={i} className="tableHeadStyle" align="left" onClick={() => props.sort(data.name, 'user')} >
                  <div className="sortSection">
                    <Typography className="textTheme">{data.name}</Typography>
                    <div className={data.name === props.audit_filter.sort_key ? "sort-icons" : "sort-icons-hide"} >
                      {data.name === props.audit_filter.sort_key && props.audit_filter.sort_order === true ? (
                        <ArrowUpward fontSize="small" style={{ color: "grey" }} />
                      ) : data.name === props.audit_filter.sort_key && props.audit_filter.sort_order === false ?
                        <ArrowDownward style={{ color: "grey" }} fontSize="small" /> : null}
                    </div>

                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {userData && userData.map((res) =>
              <TableRow>
                <TableCell className="tableCellStyle"><div>{res.name}</div></TableCell>
                <TableCell className="tableCellStyle"><div>{res.action}</div></TableCell>
                <TableCell className="tableCellStyle"><div>{res.field ? res.field : '-'}</div></TableCell>
                <TableCell className="tableCellStyle"><div>{Array.isArray(res.prevValue) ? res.prevValue.join(', ') : res.prevValue ? Array.isArray(res.prevValue) ? res.prevValue.join(', ') : res.prevValue : "-"}</div></TableCell>
                <TableCell className="tableCellStyle"><div>{Array.isArray(res.currentValue) ? res.currentValue.join(', ') : res.currentValue ? Array.isArray(res.currentValue) ? res.currentValue.join(', ') : res.currentValue : '-'}</div></TableCell>
                <TableCell className="tableCellStyle"><div>{res.user_name ? res.user_name : '-'}</div></TableCell>
                <TableCell className="tableCellStyle"><div>{ConvertUtcToLocal(res.last_updated_date + " " + res.last_updated_time + " UTC", "MM/DD/YYYY, hh:mm A")}</div></TableCell>
              </TableRow>
            )}
          </TableBody>
          {userData.length == 0 && <TableCell className="tableCellStyle" colSpan={7} style={{ textAlign: 'center' }}><DialogModal /></TableCell>}
        </Table>
      </TableContainer>
    </div>
    <div>
      <TablePage
        count={props?.audit_log_length}
        recordsPerPage={props?.recordsPerPage}
        pageNum={props?.pageNum}
        handleChangePage={props.handleChangePage}
        refresh_list={props?.refresh_list}
      />
    </div>
  </>
};

export default UserLogTable;
