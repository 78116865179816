import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import * as c from "../../../utils/constants/constants";
import {Launch} from '@mui/icons-material';
import { ConvertUtcToLocal, GetCurrentDateAndTime } from "../../../commonFunctions/commonFunctions";

/* Making an API call to fetch all data accoding to filters applied.
Only after data is fetched, export component will be triggered.
This is achieved using "ref" logic. ref is assigned to click "export" link.
Export link is clicked after a setimeout inside success block of API call.
All dta along with headers and export info is combined to single json and passed to the library
*/

const ExportAudit = (props) => {

    const [data , updateData] = useState([])

    useEffect(()=>{
        let temp = [];
        let updatedData = props.audit_data ? JSON.parse(JSON.stringify(props.audit_data)) : [];

        updatedData && updatedData.forEach((data, i) => {
            if (data?.modified_entity?.length === 0) {
                temp.push(data);
            } else {
                (data?.modified_entity || []).forEach((res) => {
                    temp.push({ ...data, ...res });
                });
            }
            data.last_updated_date = ConvertUtcToLocal(data.last_updated_date + " " + data.last_updated_time + " UTC", "MM/DD/YYYY hh:mm A")
            data.document_received_date = ConvertUtcToLocal(data.document_received_date + " "+ data.document_received_time + " UTC", "MM/DD/YYYY hh:mm A");
        });

        updateData(temp);
    },[props.audit_data])

    const dummy_headers = [
        { label: "Document: " +c.PRODUCT_NAME+ " Audit Report - Document",key: "document_name" },
        { label: "", key: "original_file_name" },
        { label: "", key: "location" },
        { label: "", key: "Sub_Category" },
        { label: "", key: "name" },
        { label: "", key: "action" },
        { label: "", key: "field" },
        { label: "", key: "prevValue" },
        { label: "", key: "currentValue" },
        { label: "", key: "last_updated_date" },
        { label: "", key: "document_received_date" },

      ];

      const export_info = [
        {document_name: "Export By:", original_file_name: localStorage.getItem(c.USER_NAME)},
        {document_name: "Export Date/Time:", original_file_name: GetCurrentDateAndTime("MM/DD/YYYY, hh:mm A")}
    ]

    const audit_headers = [
        { document_name: ""},
        {  document_name : "Document Name", original_file_name:"Document Old Name" , location:"Location" ,Sub_Category: "Sub Category",name: "User Name",
        action: "Action",field: "Field",prevValue:"Previous Value",currentValue: "Current Value",last_updated_date:"Updated on",document_received_date:"Received on"}
      ];
      
    const report_end = [
        {document_name: ""},
        {document_name: "<End of Report>"}
    ]
      
      let combined_json = [];
      combined_json = export_info.concat([{document_name: ""}, {document_name: "Audit Report - Document"}]).concat(audit_headers).concat(data).concat(report_end);

return(
        <div className="audit-export-link">
        <Launch  />
        <input type="button" className = "button-as-link" value="Export" onClick={props.generateReport} />
        <CSVLink
          headers={dummy_headers}
          filename="readabl-audit-document-report.csv"
          data={combined_json}
          ref={props.csvLinkEl}
        />
        </div>
)
}
export default ExportAudit;